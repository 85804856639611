import React from "react"
import Layout from "../components/Layout"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import Heading from "../components/Heading"
import PhotoMasonry from "../components/Masonry"

export default function AnalogueProjectDetails({ data: { graphCmsAnalogue } }) {
  return (
    <Layout title="Katharina Kohler | Analog" description={graphCmsAnalogue.heading + " - " + graphCmsAnalogue.description + " by Katharina Kohler, Berlin, Germany"}>
      <div className="flex justify-end">
        <Link to="/analog">
          <Heading>Analog</Heading>
        </Link>
      </div>
      <div className="flex justify-center">
        <div>
          <Link
            to="/analog"
            className="block hover:font-bold hover:underline"
          >
            &lt; back
          </Link>

          {graphCmsAnalogue.image.length === 1 ? (
            <GatsbyImage
              key={graphCmsAnalogue.image[0].localFile.id}
              alt={graphCmsAnalogue.heading}
              image={getImage(graphCmsAnalogue.image[0].localFile)}
            />
          ) : (
            <PhotoMasonry>
              {graphCmsAnalogue.image.map(image => (
                <GatsbyImage
                  key={graphCmsAnalogue.remoteId}
                  alt={graphCmsAnalogue.heading}
                  image={getImage(image.localFile)}
                  className="mb-4"
                />
              ))}
            </PhotoMasonry>
          )}

          <h2 className="text-md sm:text-xl font-bold mt-2">
            {graphCmsAnalogue.heading}
          </h2>
          <h3
            className={
              "text-xs sm:text-sm" + graphCmsAnalogue.subheading ? "hidden" : ""
            }
          >
            {graphCmsAnalogue.subheading}
          </h3>
          <p>{graphCmsAnalogue.description}</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AnalogueProjectPage($remoteId: ID) {
    graphCmsAnalogue(remoteId: { eq: $remoteId }) {
      heading
      subheading
      description
      remoteId
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 700
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
